// Bootstrap CSS form valid class names are messed up because of assets/_synergi.style.css
// This is to base these off of bootstrap and not collide with anything else
//
// XXX: DO NOT make a habbit of this. I would prefer us to stick with a small few
// CSS libraries (bootstrap or material but not both).
label.required::after,
mat-label.required::after {
  content: '*';
  color: red;
}

input.invalid-field {
  border-color: red !important;
}

// Any ng-select list that has a parent class of .invalid-field
.invalid-field .ng-select-container {
  border-color: red !important;
}

@mixin feedback-validity() {
  &-valid {
    @include feedback-position() {
      color: green;
      @content;
    }
  }
  &-invalid {
    @include feedback-position() {
      color: red;
      @content;
    }
  }
}

@mixin feedback-position() {
  &-top {
    margin-bottom: 0.25rem;
    @content;
  }

  &-bottom {
    margin-top: 0.25rem;
    @content;
  }
}

.feedback {
  @include feedback-validity() {
    font-size: 80%;
    width: 100%;
  }
}

#hotTable {
  // This is so Headers of Handsontable Wrap
  .handsontable thead th {
    white-space: normal !important;
    word-wrap: break-word;
    .relative {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
    }
  }

  .dirtyRow > TD {
    font-weight: normal;
    color: green;
    background-color: rgb(239, 249, 239);
  }

  .invalidRow > TD {
    font-weight: normal;
    color: red;
    background-color: rgb(249, 239, 239);
  }

  TD.dirtyCell {
    font-weight: bold;
    color: green;
    background-color: rgb(204, 238, 204);
  }

  TD.invalidCell {
    font-weight: bold;
    color: Red;
    background-color: rgb(238, 204, 204);
  }
}

.htDatepickerHolder {
  position: fixed !important;
}

.htMenu.htDropdownMenu {
  position: fixed !important;
}
